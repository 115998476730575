// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// FontAwesome
$fa-font-path:"../webfonts";

// AdminLTE
@import '~admin-lte/dist/css/adminlte.css';

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

//Select2
@import "~admin-lte/plugins/select2/css/select2.min.css";
@import "~select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css";

//SweetAlert
@import "~admin-lte/plugins/sweetalert2-theme-bootstrap-4/bootstrap-4.min.css";

//JQVMap
@import "~admin-lte/plugins/jqvmap/jqvmap.min.css";
//Tempusdominus
@import "~admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css";
//Datatable
@import "~admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css";
@import "~admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css";
@import "~admin-lte/plugins/datatables-buttons/css/buttons.bootstrap4.min.css";
//overlayScrollbars
@import "~admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
//summernote
@import "~admin-lte/plugins/summernote/summernote-bs4.min.css";
//data Rangepickers
@import "~admin-lte/plugins/daterangepicker/daterangepicker.css";
